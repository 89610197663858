body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
q:before,
q:after {
  content: '';
}
abbr,
acronym {
  border: 0;
}
input,
textarea {
  outline: none;
}
textarea {
  resize: none;
}
:focus {
  outline: none;
}
html {
  font-size: 62.5%;
}
html,
body {
  height: 100%;
}
body {
  min-width: 360px;
  font-family: 'Comfortaa', cursive;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
body.fixed {
  height: 100vh;
  overflow: hidden;
}
body.page .header {
  background-color: #fff;
}
header,
aside,
section,
footer {
  display: block;
}
::-webkit-input-placeholder {
  color: #b0b0b0;
}
::-moz-placeholder {
  color: #b0b0b0;
}
:-moz-placeholder {
  color: #b0b0b0;
}
:-ms-input-placeholder {
  color: #b0b0b0;
}
:focus::-webkit-input-placeholder {
  color: transparent;
}
:focus::-moz-placeholder {
  color: transparent;
}
:focus:-moz-placeholder {
  color: transparent;
}
:focus:-ms-input-placeholder {
  color: transparent;
}
a {
  color: #fd7e01;
  text-decoration: none;
  position: relative;
  transition: color 300ms;
}
a:hover {
  color: #fd6b01;
}
img {
  max-width: 100%;
  display: block;
}
h1 {
  line-height: 56px;
  font-size: 48px;
  font-weight: 700;
}
@media (max-width: 650px) {
  h1 {
    line-height: 36px;
    font-size: 32px;
  }
}
h2 {
  line-height: 52px;
  font-size: 44px;
  font-weight: 700;
}
@media (max-width: 650px) {
  h2 {
    line-height: 36px;
    font-size: 28px;
  }
}
h3 {
  line-height: 48px;
  font-size: 40px;
  font-weight: 700;
}
@media (max-width: 650px) {
  h3 {
    line-height: 32px;
    font-size: 24px;
  }
}
h4 {
  line-height: 32px;
  font-size: 24px;
  font-weight: 700;
}
@media (max-width: 650px) {
  h4 {
    line-height: 24px;
    font-size: 18px;
  }
}
.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.content {
  width: 100%;
  margin-bottom: auto;
  padding: 89px 0 0;
}
@media (max-width: 768px) {
  .content {
    padding: 62px 0 0;
  }
}
.box-center {
  padding: 0 32px;
  width: 100%;
  max-width: 1212px;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 650px) {
  .box-center {
    padding: 0 16px;
  }
}
.main-nav__list {
  display: inline-flex;
  gap: 48px;
  line-height: 32px;
  font-size: 20px;
  transition: color 300ms;
}
.main-nav__item.active .main-nav__link,
.main-nav__item:hover .main-nav__link {
  color: #fd7e01;
}
.main-nav__link {
  color: #000;
}
.btn {
  padding: 0 54px;
  height: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0 16px;
  line-height: 32px;
  font-size: 24px;
  color: #fff;
  white-space: nowrap;
  background-color: #fd7e01;
  border: 1px solid #fd7e01;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background 300ms;
}
.btn:hover {
  color: #fff;
  background-color: #fd6b01;
}
.btn img {
  margin-top: -4px;
  width: 24px;
  flex-shrink: 0;
}
.btns-box {
  width: 100%;
  display: flex;
  align-items: center;
}
.field {
  width: 100%;
  height: 56px;
  margin-bottom: 16px;
  padding: 0 24px;
  font-family: 'Comfortaa', cursive;
  line-height: 24px;
  font-size: 16px;
  color: #000;
  background: none;
  border: 1px solid #b0b0b0;
  border-radius: 8px;
  box-sizing: border-box;
}
.field:focus {
  box-shadow: 0 0 10px 0 rgba(216,216,216,0.8);
}
textarea {
  width: 100%;
  height: 128px;
  margin-bottom: 16px;
  padding: 16px 24px;
  display: block;
  font-family: 'Comfortaa', cursive;
  line-height: 24px;
  font-size: 16px;
  color: #000;
  border: 0;
  background: none;
  border: 1px solid #b0b0b0;
  border-radius: 8px;
  box-sizing: border-box;
}
textarea:focus {
  box-shadow: 0 0 10px 0 rgba(216,216,216,0.8);
}
.header {
  width: 100%;
  padding: 24px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #fef7ed;
}
@media (max-width: 768px) {
  .header {
    padding: 16px 0;
  }
}
@media (max-width: 650px) {
  .header {
    padding: 0;
  }
}
.header__nav {
  position: relative;
}
@media (max-width: 768px) {
  .header__nav {
    padding: 64px 0 160px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 100%;
    z-index: -1;
    background-color: #fef7ed;
    transform: translateX(0);
    transition: transform 300ms;
    box-sizing: border-box;
  }
}
@media (max-width: 768px) {
  .header__nav.isOpen {
    transform: translateX(-100%);
  }
}
.header__btn-mob {
  width: 18px;
  height: 12px;
  display: none;
  position: absolute;
  top: 50%;
  right: 16px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  box-sizing: border-box;
  transform: translateY(-50%);
  cursor: pointer;
  transition: border 300ms;
}
@media (max-width: 768px) {
  .header__btn-mob {
    display: block;
  }
}
.header__btn-mob:before,
.header__btn-mob:after {
  content: '';
  margin-top: -1px;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: currentColor;
  transform: rotate(0);
  transition: transform 300ms;
}
.header__btn-mob.isOpen {
  border: 0;
}
.header__btn-mob.isOpen:before {
  transform: rotate(45deg);
}
.header__btn-mob.isOpen:after {
  transform: rotate(-45deg);
}
@media (max-width: 768px) {
  .header .main-nav__list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
  }
}
.header .box-center {
  max-width: 1528px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 650px) {
  .header .box-center {
    padding: 17px;
    background-color: #fef7ed;
  }
}
@media (max-width: 768px) {
  .header .logo {
    width: 120px;
    height: 30px;
  }
}
.header .social__list {
  display: none;
  gap: 48px;
}
@media (max-width: 768px) {
  .header .social__list {
    display: inline-flex;
  }
}
.header .social__list-item {
  width: 32px;
  height: 32px;
  background-color: #15a5c5;
  border-radius: 4px;
}
.header .mail__link {
  display: none;
  bottom: 104px;
  color: #15a5c5;
}
@media (max-width: 768px) {
  .header .mail__link {
    display: inline-flex;
  }
}
.lang {
  display: inline-flex;
  position: relative;
}
@media (max-width: 768px) {
  .lang {
    margin-right: 72px;
  }
}
.lang__active {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.lang__active .lang__list-item {
  padding: 0;
}
.lang__active .lang__list-item:before {
  display: none;
}
.lang__active .lang__list-item:hover span {
  color: #fd7e01;
}
.lang__active .lang__list-item img {
  width: 20px;
  flex-shrink: 0;
}
.lang__active .lang__list-item svg {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.lang__active .lang__list-item span {
  text-transform: uppercase;
  white-space: nowrap;
  transition: color 300ms;
}
.lang__active .lang__list-item span span {
  display: none;
}
.lang__list {
  min-width: 285px;
  width: auto;
  max-height: 0;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 34px 114px 0 rgba(0,0,0,0.2);
  transition: max-height 300ms;
}
@media (max-width: 650px) {
  .lang__list {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 64px 0;
    position: fixed;
    top: 0;
    left: 100%;
    z-index: -1;
    box-shadow: none;
    transform: translateX(0);
    transition: transform 300ms;
    box-sizing: border-box;
  }
}
.lang__list.show {
  max-height: 390px;
}
@media (max-width: 650px) {
  .lang__list.show {
    max-height: 100%;
    transform: translateX(-100%);
  }
}
.lang__list-item {
  width: 100%;
  padding: 12px 80px 12px 24px;
  display: flex;
  gap: 8px;
  position: relative;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;
  cursor: pointer;
}
.lang__list-item:last-child {
  border: 0;
}
.lang__list-item:hover span {
  color: #fd7e01;
}
.lang__list-item span {
  display: flex;
  align-items: center;
  gap: 4px;
  transition: color 300ms;
}
.lang__list-item span span {
  display: block;
  text-transform: none;
}
.lang__list-item.checked:before {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 24px;
  background: url("./../img/svgicons/icnsprite.svg#check") no-repeat center;
  filter: invert(69%) sepia(62%) saturate(2006%) hue-rotate(150deg) brightness(78%) contrast(96%);
  transform: translateY(-50%);
}
.lang__list-item svg {
  width: 20px;
  height: 20px;
}
.lang__list-back {
  padding: 12px 24px;
  display: none;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}
@media (max-width: 650px) {
  .lang__list-back {
    display: block;
  }
}
.lang__list-back:before {
  content: '';
  margin-top: -1px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 24px;
  border-bottom: 2px solid #15a5c5;
  border-left: 2px solid #15a5c5;
  box-sizing: border-box;
  transform: translateY(-50%) rotate(45deg);
}
.footer {
  width: 100%;
  color: #fff;
  position: relative;
}
.footer__top {
  padding: 32px 0;
  width: 100%;
  position: relative;
  background-color: #414652;
}
@media (max-width: 850px) {
  .footer__top .box-center {
    flex-direction: column;
  }
}
.footer__bottom {
  padding: 24px 0;
  width: 100%;
  background-color: #373a42;
}
@media (max-width: 650px) {
  .footer__bottom {
    padding: 16px 0;
  }
}
@media (max-width: 650px) {
  .footer__bottom .box-center {
    justify-content: center;
  }
}
.footer__box {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 22px;
}
.footer .box-center {
  max-width: 1696px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1024px) {
  .footer .main-nav__list {
    gap: 24px;
    line-height: 24px;
    font-size: 16px;
  }
}
@media (max-width: 850px) {
  .footer .main-nav__list {
    margin: 32px 0 176px;
  }
}
@media (max-width: 650px) {
  .footer .main-nav__list {
    margin: 32px 0 232px;
    flex-direction: column;
  }
}
.footer .main-nav__item.active .main-nav__link,
.footer .main-nav__item:hover .main-nav__link {
  color: #fd7e01;
}
.footer .main-nav__link {
  color: #fff;
}
.footer .btns-box {
  margin-top: 24px;
  justify-content: center;
}
@media (max-width: 850px) {
  .footer .btns-box {
    margin: 0;
    position: absolute;
    left: 50%;
    bottom: 112px;
    transform: translateX(-50%);
  }
}
@media (max-width: 650px) {
  .footer .btns-box {
    bottom: 168px;
  }
}
.footer .btns-box svg {
  width: 24px;
  height: 24px;
}
@media (max-width: 768px) {
  .footer .mail__link {
    position: static;
    transform: translateX(0);
  }
}
@media (max-width: 650px) {
  .footer .mail__link {
    position: absolute;
    transform: translateX(-50%);
  }
}
.social__list {
  display: inline-flex;
  align-items: center;
  gap: 24px;
}
@media (max-width: 850px) {
  .social__list {
    position: absolute;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);
  }
}
.social__list-item {
  width: 64px;
  height: 64px;
}
@media (max-width: 1024px) {
  .social__list-item {
    width: 48px;
    height: 48px;
  }
}
.social__list-link {
  height: 100%;
  display: block;
  transition: opacity 300ms;
}
.social__list-link:before {
  display: none;
}
.social__list-link:hover svg {
  fill: #fd7e01;
}
.social__list-link img,
.social__list-link svg {
  width: 100%;
  height: 100%;
}
.social__list-link svg {
  fill: #fff;
  transition: fill 300ms;
}
.logo {
  width: 172px;
  height: 41px;
}
.logo svg {
  width: 100%;
  height: 100%;
}
.mail__link {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  color: #fd7e01;
  transition: color 300ms;
}
@media (max-width: 768px) {
  .mail__link {
    position: absolute;
    left: 50%;
    bottom: 168px;
    color: #fff;
    transform: translateX(-50%);
  }
}
.mail__link svg {
  width: 24px;
  height: 24px;
  fill: currentColor;
  transition: fill 300ms;
}
.mail__link:before {
  display: none;
}
.mail__link:hover {
  color: #fd6b01;
}
.content__wrap {
  width: 100%;
  padding: 164px 0;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .content__wrap {
    padding: 80px 0;
  }
}
@media (max-width: 650px) {
  .content__wrap {
    padding: 48px 0;
  }
}
.content__wrap h2 {
  margin-bottom: 104px;
  text-align: center;
}
@media (max-width: 1024px) {
  .content__wrap h2 {
    margin-bottom: 52px;
  }
}
.content__wrap-columns {
  max-width: 1044px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 30px;
}
@media (max-width: 920px) {
  .content__wrap-columns {
    flex-direction: column;
  }
}
.content__wrap-img {
  width: 420px;
  flex-shrink: 0;
}
@media (max-width: 1024px) {
  .content__wrap-img {
    max-width: 360px;
    width: 100%;
  }
}
@media (max-width: 920px) {
  .content__wrap-img {
    margin-bottom: 32px;
  }
}
.content__wrap-text {
  width: 100%;
  max-width: 520px;
  padding-left: 148px;
  position: relative;
  box-sizing: border-box;
}
@media (max-width: 920px) {
  .content__wrap-text {
    padding-left: 84px;
    order: 1;
  }
}
@media (max-width: 650px) {
  .content__wrap-text {
    max-width: 360px;
    width: 100%;
  }
}
.content__wrap-text h3 {
  margin-bottom: 24px;
}
@media (max-width: 650px) {
  .content__wrap-text h3 {
    margin-bottom: 16px;
  }
}
.content__wrap-text p {
  line-height: 32px;
  font-size: 20px;
}
@media (max-width: 650px) {
  .content__wrap-text p {
    line-height: 24px;
    font-size: 16px;
  }
}
.content__wrap-step {
  width: 100px;
  height: 100px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 0;
  line-height: 100px;
  font-size: 40px;
  font-weight: 500;
  border: 3px solid currentColor;
  border-radius: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}
@media (max-width: 920px) {
  .content__wrap-step {
    width: 68px;
    height: 68px;
    padding-top: 4px;
    line-height: 68px;
    font-size: 24px;
    top: 0;
    transform: translateY(0);
  }
}
.content__wrap--center .box-center {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content__wrap--orange {
  background-color: #fef7ed;
}
.content__wrap--orange h3,
.content__wrap--orange .content__wrap-step {
  color: #fd7e01;
}
.content__wrap--blue {
  background-color: #edfbfe;
}
.content__wrap--blue h3,
.content__wrap--blue .content__wrap-step {
  color: #15a5c5;
}
.content__wrap--violet {
  background-color: #f5eeff;
}
.content__wrap--violet h3,
.content__wrap--violet .content__wrap-step {
  color: #ad65ff;
}
.content__wrap .videobox video,
.content__wrap .videobox iframe,
.content__wrap .videobox img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border: 0;
}
.content__start {
  min-height: calc(100vh - 89px);
  height: auto;
  padding: 32px 0 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .content__start {
    padding: 32px 0 90px;
  }
}
@media (max-width: 767px) {
  .content__start {
    min-height: calc(100vh - 62px);
    padding-bottom: 48px;
  }
}
.content__start h1 {
  max-width: 814px;
  margin-bottom: 44px;
  text-align: center;
}
@media (max-width: 650px) {
  .content__start h1 {
    margin-bottom: 32px;
  }
}
.content__start desc {
  max-width: 560px;
  margin-bottom: 64px;
  line-height: 32px;
  font-size: 20px;
  text-align: center;
}
@media (max-width: 1024px) {
  .content__start desc {
    margin-bottom: 32px;
  }
}
@media (max-width: 768px) {
  .content__start desc {
    margin-bottom: 282px;
  }
}
@media (max-width: 650px) {
  .content__start desc {
    line-height: 24px;
    font-size: 16px;
  }
}
.content__start .scroll-down {
  padding-top: 33px;
  display: inline-flex;
  line-height: 12px;
  font-size: 11px;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  bottom: -64px;
  background: url("./../img/svgicons/scroll-down.svg") no-repeat center top;
  transform: translateX(-50%);
  cursor: pointer;
  transition: all 300ms;
}
.content__start .scroll-down:hover {
  color: #fd6b01;
  background: url("./../img/svgicons/scroll-down-hover.svg") no-repeat center top;
}
@media (max-width: 767px) {
  .content__start .scroll-down {
    display: none;
  }
}
.content__start .box-center {
  height: auto;
  position: relative;
}
.content__start .btns-box {
  justify-content: center;
}
.content__work .content__wrap-columns {
  max-width: 1020px;
}
.content__select {
  position: relative;
}
.content__select:before {
  content: '';
  margin-left: -210px;
  width: 527px;
  height: 1274px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: url("./../img/line.png") no-repeat center;
  transform: translate(-50%, -50%);
}
@media (max-width: 1120px) {
  .content__select:before {
    display: none;
  }
}
.content__select .content__wrap-text {
  order: 1;
}
.content__media h3 {
  text-align: center;
}
.content__media p {
  max-width: 830px;
  width: 100%;
  margin: 24px auto 0;
  line-height: 32px;
  font-size: 20px;
  text-align: center;
}
@media (max-width: 650px) {
  .content__media p {
    line-height: 24px;
    font-size: 16px;
  }
}
.content__idea .content__wrap-columns {
  max-width: 100%;
}
.content__idea .content__wrap-text {
  max-width: 558px;
  padding: 0;
}
@media (max-width: 920px) {
  .content__idea .content__wrap-text {
    max-width: 100%;
    margin-bottom: 24px;
    order: 0;
  }
}
@media (max-width: 920px) {
  .content__idea .content__wrap-text p,
  .content__idea .content__wrap-text h3 {
    text-align: center;
  }
}
.content__idea .content__wrap-img {
  max-width: 558px;
  width: 100%;
  flex-shrink: inherit;
}
@media (max-width: 920px) {
  .content__idea .content__wrap-img {
    margin-bottom: 0;
  }
}
.content__purpose .content__wrap-columns {
  max-width: 865px;
}
.content__purpose .content__wrap-text {
  max-width: 430px;
  padding: 0;
  order: 1;
}
@media (max-width: 920px) {
  .content__purpose .content__wrap-text {
    max-width: 100%;
    margin-bottom: 24px;
    order: 0;
  }
}
@media (max-width: 920px) {
  .content__purpose .content__wrap-text p,
  .content__purpose .content__wrap-text h3 {
    text-align: center;
  }
}
.content__purpose .content__wrap-img {
  max-width: 280px;
}
@media (max-width: 920px) {
  .content__purpose .content__wrap-img {
    margin-bottom: 0;
  }
}
@media (max-width: 650px) {
  .content__purpose .content__wrap-img {
    max-width: 162px;
  }
}
.content__trust .content__wrap-columns {
  max-width: 930px;
}
.content__trust .content__wrap-text {
  max-width: 530px;
  padding: 0;
}
@media (max-width: 920px) {
  .content__trust .content__wrap-text {
    max-width: 100%;
    margin-bottom: 24px;
    order: 0;
  }
}
@media (max-width: 920px) {
  .content__trust .content__wrap-text p,
  .content__trust .content__wrap-text h3 {
    text-align: center;
  }
}
.content__trust .content__wrap-text p {
  max-width: 430px;
}
@media (max-width: 920px) {
  .content__trust .content__wrap-text p {
    max-width: 100%;
  }
}
.content__trust .content__wrap-img {
  max-width: 247px;
}
@media (max-width: 920px) {
  .content__trust .content__wrap-img {
    margin-bottom: 0;
  }
}
.content__facts h2 {
  margin-bottom: 32px;
  line-height: 48px;
  font-size: 40px;
  color: #ad65ff;
  text-align: center;
}
.content__facts-desc {
  max-width: 585px;
  margin: 0 auto 64px;
  color: #ad65ff;
  line-height: 32px;
  font-size: 24px;
  text-align: center;
}
.content__facts .content__wrap-columns {
  max-width: 952px;
  align-items: flex-start;
}
@media (max-width: 920px) {
  .content__facts .content__wrap-columns {
    gap: 32px;
  }
}
.content__facts .content__wrap-text {
  max-width: 495px;
  padding: 0;
}
@media (max-width: 920px) {
  .content__facts .content__wrap-text {
    max-width: 500px;
    margin: 0 auto;
  }
}
.content__facts .content__wrap-text p {
  line-height: 32px;
  font-size: 24px;
}
@media (max-width: 920px) {
  .content__facts .content__wrap-text p {
    text-align: center;
  }
}
.content__facts .content__wrap-text:last-child {
  max-width: 425px;
}
@media (max-width: 920px) {
  .content__facts .content__wrap-text:last-child {
    max-width: 100%;
    margin: 0 auto;
  }
}
.content__facts .content__wrap-text:last-child p {
  font-size: 20px;
}
.videobox {
  max-width: 545px;
  width: 100%;
  margin-bottom: 48px;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .videobox {
    margin-bottom: 24px;
  }
}
.videobox__wrap {
  padding-top: 56.29%;
  height: 0;
  position: relative;
}
.videobox__wrap:hover .videobox__play {
  opacity: 0.7;
}
.videobox__wrap--enabled {
  cursor: pointer;
}
.videobox__wrap--enabled .videobox__play {
  display: block;
}
.videobox__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.videobox video,
.videobox iframe,
.videobox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 0;
}
.videobox__play {
  padding: 0;
  width: 70px;
  height: 48px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  background: url("../img/icn-play.svg") no-repeat center;
  background-size: cover;
  transition: opacity 250ms ease;
  cursor: pointer;
}
.videobox__play:focus {
  opacity: 0.7;
}
.num-list {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 650px) {
  .num-list {
    gap: 16px;
  }
}
.num-list__item {
  width: calc((100% - 90px) / 4);
  padding: 48px 16px;
  text-align: center;
  background-color: #fff;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .num-list__item {
    width: calc((100% - 30px) / 2);
  }
}
@media (max-width: 650px) {
  .num-list__item {
    width: calc((100% - 16px) / 2);
    padding: 24px 16px;
  }
}
@media (max-width: 420px) {
  .num-list__item {
    width: 100%;
  }
}
.num-list__title {
  margin-bottom: 24px;
  line-height: 56px;
  font-size: 48px;
  color: #fd7e01;
  font-weight: 700;
}
@media (max-width: 650px) {
  .num-list__title {
    line-height: 36px;
    font-size: 32px;
  }
}
.num-list__desc {
  line-height: 32px;
  font-size: 20px;
}
@media (max-width: 650px) {
  .num-list__desc {
    line-height: 24px;
    font-size: 16px;
  }
}
.facts-list {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}
.facts-list__item {
  padding: 8px 24px;
  align-self: flex-start;
  border: 1px solid #414652;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
}
.facts-list__item:nth-child(2n) .facts-list__link {
  color: #ad65ff;
}
.facts-list__item:nth-child(3n) .facts-list__link {
  color: #15a5c5;
}
.facts-list__link {
  margin-left: 7px;
}
.pagenav__list {
  width: 100%;
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.pagenav__list-link {
  width: 40px;
  height: 40px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 32px;
  font-size: 24px;
  color: #414652;
  font-weight: 700;
  border: 2px solid currentColor;
  border-radius: 50%;
  box-sizing: border-box;
}
.pagenav__list span {
  color: #fd7e01;
}
.anim-box {
  position: absolute;
  left: 50%;
}
.anim-box--headphone {
  width: 185px;
  top: 31%;
  transform: translateX(-426%);
}
@media (max-width: 768px) {
  .anim-box--headphone {
    display: none;
  }
}
.anim-box--watch {
  width: 154px;
  top: 26%;
  transform: translateX(-330%);
}
@media (max-width: 768px) {
  .anim-box--watch {
    display: none;
  }
}
.anim-box--glasses {
  width: 500px;
  top: 18%;
  transform: translateX(67%);
}
@media (max-width: 768px) {
  .anim-box--glasses {
    width: 328px;
    height: 218px;
    top: auto;
    bottom: 96px;
    transform: translateX(-50%);
  }
}
.anim-box--phone {
  width: 196px;
  top: 65%;
  transform: translateX(144%);
}
@media (max-width: 768px) {
  .anim-box--phone {
    display: none;
  }
}
.anim-box--pict {
  width: 360px;
  top: 72%;
  transform: translateX(-174%);
}
@media (max-width: 768px) {
  .anim-box--pict {
    display: none;
  }
}
.page-content {
  width: 100%;
  padding: 104px 0;
}
@media (max-width: 768px) {
  .page-content {
    padding: 80px 0;
  }
}
@media (max-width: 650px) {
  .page-content {
    padding: 48px 0;
  }
}
.page-content--bg {
  background: url("./../img/pricing-bg.png") no-repeat center bottom;
  background-size: cover;
}
@media (max-width: 767px) {
  .page-content--bg {
    background: none;
  }
}
.page-content .box-center {
  max-width: 754px;
}
.page-content h1 {
  margin-bottom: 64px;
  line-height: 52px;
  font-size: 44px;
  text-align: center;
}
@media (max-width: 768px) {
  .page-content h1 {
    margin-bottom: 32px;
  }
}
@media (max-width: 650px) {
  .page-content h1 {
    line-height: 36px;
    font-size: 28px;
  }
}
.page-content__desc {
  margin-bottom: 32px;
}
@media (max-width: 650px) {
  .page-content__desc {
    font-size: 14px;
  }
}
.page-content h4 {
  margin-bottom: 24px;
  color: #15a5c5;
}
@media (max-width: 650px) {
  .page-content h4 {
    margin-bottom: 16px;
  }
}
@media (max-width: 650px) {
  .page-content p {
    font-size: 14px;
  }
}
.page-content__list {
  width: 100%;
  padding-bottom: 16px;
}
@media (max-width: 650px) {
  .page-content__list {
    padding-bottom: 24px;
  }
}
.page-content__list-item,
.page-content__list-subitem {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.page-content__list-item span,
.page-content__list-subitem span {
  margin-right: 20px;
  display: block;
}
.page-content__list-item {
  margin-bottom: 24px;
}
@media (max-width: 650px) {
  .page-content__list-item {
    margin-bottom: 16px;
  }
}
.page-content__list-item span {
  line-height: 32px;
  font-size: 24px;
  color: #15a5c5;
  font-weight: 700;
}
@media (max-width: 650px) {
  .page-content__list-item span {
    line-height: 24px;
    font-size: 18px;
  }
}
.page-content__list-item h4 {
  margin: 0;
  text-transform: uppercase;
}
.page-content__list-subitem {
  margin-bottom: 16px;
}
@media (max-width: 650px) {
  .page-content__list-subitem {
    margin-bottom: 8px;
  }
}
.page-content__list-subitem span {
  line-height: 32px;
  font-size: 20px;
  color: #000;
}
@media (max-width: 650px) {
  .page-content__list-subitem span {
    line-height: 24px;
    font-size: 16px;
  }
}
.accardion {
  width: 100%;
}
.accardion__item {
  margin-bottom: 24px;
}
.accardion__item-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms;
}
.accardion__item-text {
  padding-top: 24px;
}
.accardion__item.active h4:before {
  margin-top: 0;
  transform: translateY(-50%) rotate(135deg);
}
.accardion__item h4 {
  width: 100%;
  margin: 0;
  padding: 8px 38px 16px 0;
  line-height: 32px;
  font-size: 24px;
  color: #000;
  position: relative;
  border-bottom: 1px solid #b0b0b0;
  box-sizing: border-box;
  cursor: pointer;
}
@media (max-width: 650px) {
  .accardion__item h4 {
    margin: 0;
    line-height: 24px;
    font-size: 18px;
  }
}
.accardion__item h4:before {
  content: '';
  margin-top: -3px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 12px;
  border-bottom: 2px solid #fd7e01;
  border-left: 2px solid #fd7e01;
  box-sizing: border-box;
  transform: translateY(-50%) rotate(-45deg);
}
@media (max-width: 650px) {
  .accardion__item p {
    font-size: 14px;
  }
}
.category {
  width: 100%;
  padding: 104px 0;
  background-color: #fef7ed;
}
@media (max-width: 768px) {
  .category {
    padding: 80px 0;
  }
}
@media (max-width: 650px) {
  .category {
    padding: 48px 0;
  }
}
.category h1 {
  margin-bottom: 64px;
  line-height: 52px;
  font-size: 44px;
  text-align: center;
}
@media (max-width: 1024px) {
  .category h1 {
    margin-bottom: 32px;
  }
}
@media (max-width: 650px) {
  .category h1 {
    line-height: 36px;
    font-size: 28px;
  }
}
.category__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
@media (max-width: 920px) {
  .category__list {
    gap: 16px;
  }
}
@media (max-width: 650px) {
  .category__list {
    gap: 30px;
  }
}
.category__list-item {
  width: calc((100% - 30px) / 2);
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
}
@media (max-width: 920px) {
  .category__list-item {
    width: calc((100% - 16px) / 2);
  }
}
@media (max-width: 650px) {
  .category__list-item {
    width: 100%;
  }
}
.category__list-item:hover h4 {
  color: #fd6b01;
}
.category__list-link {
  width: 100%;
  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 36px;
  box-sizing: border-box;
}
@media (max-width: 920px) {
  .category__list-link {
    padding: 16px;
    gap: 16px;
  }
}
.category__list-thumbnail {
  width: 122px;
  height: 122px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}
@media (max-width: 920px) {
  .category__list-thumbnail {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 768px) {
  .category__list-thumbnail {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 650px) {
  .category__list-thumbnail {
    width: 48px;
    height: 48px;
  }
}
.category__list-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.category__list-content {
  color: #000;
}
.category__list-content h4 {
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: color 300ms;
}
@media (max-width: 920px) {
  .category__list-content h4 {
    margin-bottom: 8px;
    line-height: 24px;
    font-size: 18px;
  }
}
.category__list-content p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 920px) {
  .category__list-content p {
    font-size: 14px;
  }
}
.single {
  width: 100%;
  padding: 104px 0;
  background-color: #fef7ed;
}
@media (max-width: 768px) {
  .single {
    padding: 80px 0;
  }
}
@media (max-width: 650px) {
  .single {
    padding: 48px 0;
  }
}
.single .box-center {
  position: relative;
}
.single h1 {
  margin-bottom: 64px;
  line-height: 52px;
  font-size: 44px;
  text-align: center;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .single h1 {
    margin-bottom: 32px;
  }
}
@media (max-width: 980px) {
  .single h1 {
    padding: 0 42px;
  }
}
@media (max-width: 650px) {
  .single h1 {
    margin-bottom: 24px;
    line-height: 36px;
    font-size: 28px;
  }
}
.single__content {
  max-width: 754px;
  width: 100%;
  margin: 0 auto;
}
.single__content img {
  width: 100%;
  margin: 32px 0;
}
@media (max-width: 650px) {
  .single__content p {
    font-size: 14px;
  }
}
.single .link-box {
  margin-top: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 24px;
}
@media (max-width: 1024px) {
  .single .link-box {
    margin-top: 32px;
  }
}
@media (max-width: 650px) {
  .single .link-box {
    margin-top: 24px;
  }
}
.single .link-box__nav {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 32px;
  font-size: 20px;
  border: 1px solid #fd7e01;
  border-radius: 8px;
  transition: background 300ms, color 300ms;
}
@media (max-width: 650px) {
  .single .link-box__nav {
    height: 40px;
    line-height: 24px;
    font-size: 16px;
  }
}
.single .link-box__nav span {
  display: block;
  position: relative;
}
.single .link-box__nav span:before {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  border-bottom: 2px solid currentColor;
  border-left: 2px solid currentColor;
  box-sizing: border-box;
}
.single .link-box__nav:hover {
  color: #fff;
  background-color: #fd7e01;
}
.single .link-box__nav--prev {
  width: 143px;
}
.single .link-box__nav--prev span {
  padding-left: 20px;
}
.single .link-box__nav--prev span:before {
  margin-top: 1px;
  transform: translateY(-50%) rotate(45deg);
  left: 0;
}
.single .link-box__nav--next {
  width: 134px;
}
.single .link-box__nav--next span {
  padding-right: 20px;
}
.single .link-box__nav--next span:before {
  transform: translateY(-50%) rotate(-135deg);
  right: 0;
}
.single .back__link {
  padding-left: 32px;
  display: inline-flex;
  line-height: 32px;
  font-size: 20px;
  position: absolute;
  top: 10px;
  left: 40px;
}
@media (max-width: 1024px) {
  .single .back__link {
    left: 20px;
  }
}
@media (max-width: 980px) {
  .single .back__link {
    width: 32px;
    height: 32px;
    padding: 0;
  }
  .single .back__link span {
    display: none;
  }
}
@media (max-width: 650px) {
  .single .back__link {
    top: 2px;
    left: 10px;
  }
}
.single .back__link:before {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 8px;
  border-bottom: 2px solid currentColor;
  border-left: 2px solid currentColor;
  box-sizing: border-box;
  transform: translateY(-50%) rotate(45deg);
}
@media (max-width: 980px) {
  .single .back__link:before {
    left: 12px;
  }
}
.single .back__link:hover {
  color: #fd6b01;
}
.pricing {
  max-width: 618px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}
@media (max-width: 650px) {
  .pricing {
    flex-direction: column;
    gap: 24px 0;
    border-radius: 0;
  }
}
.pricing__item {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d9d9d9;
  box-sizing: border-box;
}
@media (max-width: 650px) {
  .pricing__item {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border-left: 0;
  }
}
.pricing__item-header {
  height: 174px;
  padding: 24px;
  background-color: #fef7ed;
  box-sizing: border-box;
}
@media (max-width: 650px) {
  .pricing__item-header {
    height: auto;
  }
}
.pricing__item-title {
  margin-bottom: 10px;
  line-height: 44px;
  font-size: 36px;
  color: #fd7e01;
  font-weight: 700;
}
.pricing__item-text {
  color: #414652;
}
.pricing__item-body {
  background-color: #fafafa;
}
.pricing__item-col {
  width: 100%;
  height: 80px;
  padding: 24px;
  line-height: 32px;
  font-size: 20px;
  color: #414652;
  display: flex;
  align-items: center;
  position: relative;
  border-top: 1px solid #d9d9d9;
  box-sizing: border-box;
}
.pricing__item-col:before {
  content: '';
  width: 1px;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #d9d9d9;
}
@media (max-width: 650px) {
  .pricing__item-col:before {
    display: block;
  }
}
.pricing__item-col--aqua .pricing__item-val {
  color: #15a5c5;
}
.pricing__item-col svg {
  width: 32px;
  height: 32px;
}
@media (max-width: 650px) {
  .pricing__item-col svg {
    margin-left: 24px;
  }
}
.pricing__item-val {
  font-weight: 700;
}
@media (max-width: 650px) {
  .pricing__item-val {
    padding-left: 24px;
    width: 50%;
    box-sizing: border-box;
  }
}
.pricing__item-label {
  display: none;
}
@media (max-width: 650px) {
  .pricing__item-label {
    padding-right: 24px;
    display: block;
    width: 50%;
    box-sizing: border-box;
  }
}
.pricing__item:first-child {
  border: 0;
}
@media (max-width: 650px) {
  .pricing__item:first-child {
    display: none;
  }
}
.pricing__item:first-child .pricing__item-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing__item:first-child .pricing__item-label {
  display: block;
}
.static {
  width: 100%;
  padding: 104px 0;
  background-color: #fef7ed;
}
@media (max-width: 768px) {
  .static {
    padding: 80px 0;
  }
}
@media (max-width: 650px) {
  .static {
    padding: 48px 0;
  }
}
.static h1 {
  margin: 0 auto 32px;
  line-height: 52px;
  font-size: 44px;
  text-align: center;
}
@media (max-width: 1024px) {
  .static h1 {
    margin-bottom: 32px;
  }
}
@media (max-width: 650px) {
  .static h1 {
    line-height: 36px;
    font-size: 28px;
  }
}
.static__desc {
  max-width: 548px;
  width: 100%;
  margin: 0 auto 64px;
  line-height: 32px;
  font-size: 20px;
  text-align: center;
}
@media (max-width: 650px) {
  .static__desc {
    margin-bottom: 32px;
    line-height: 24px;
    font-size: 16px;
  }
}
.static img {
  margin: 0 auto;
}
.static .box-center {
  max-width: 790px;
}
.static .btns-box {
  margin-top: 64px;
  justify-content: center;
}
@media (max-width: 650px) {
  .static .btns-box {
    margin-top: 32px;
  }
}
.form-area {
  max-width: 362px;
  width: 100%;
  margin: 0 auto 60px;
}
.form-area h4 {
  margin-bottom: 16px;
  line-height: 32px;
  font-size: 20px;
  text-align: center;
}
@media (max-width: 650px) {
  .form-area h4 {
    line-height: 24px;
    font-size: 16px;
  }
}
.form-area .btn {
  width: 100%;
}
